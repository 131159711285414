
import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import { Action, Mutation } from "vuex-class";
import { CanGenerateTimelineEntries } from "@/mixins/can-generate-timeline-entries";
import { HasFormErrors } from "@/mixins/has-form-errors";
import { CreateAccountTaskPayload, AccountTask } from "@/store/modules/account-task.store";

// Types
import { CreateTaskPayload, Task, TaskStatus } from "@/store/modules/task.store";

@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
        UserSelect: () => import("@/components/forms/user-select.vue"),
        TaskStatusSelect: () => import("@/components/forms/task-status-select.vue"),
        DocumentSelect: () => import("@/components/forms/document-select.vue"),
        FormDate: () => import("@/components/forms/date.vue"),
    },
})
export default class DossierTabPartTasksCreateTask extends Mixins(CanGenerateTimelineEntries, HasFormErrors) {
    @Mutation("task/UPDATE_MODEL") mutateTask!: (payload: Task) => void;

    @Action("task/create") createTask!: (payload: CreateTaskPayload) => Promise<Task>;

    @Action("account-task/create") createAccountTask!: (payload: CreateAccountTaskPayload) => Promise<AccountTask>;

    @Prop({ default: null }) group!: string | null;

    @Prop({ default: null }) category!: string | null;

    @Prop({ required: true }) activityId!: number;

    @Prop({ required: false, default: 999 }) ordinal!: number;

    @Prop({ default: null }) defaultAssignee!: Account | null;

    @Prop({ required: true }) placeholder!: string;

    @Prop({ required: true }) users!: Array<{ id: number; first_name: string; last_name: string }>;

    @Prop({ required: true }) documents!: Array<{ id: number; name: string }>;

    @Prop({ default: false }) readonly isDossier!: boolean;

    loading: boolean = false;

    payload: CreateTaskPayload = {
        title: "",
        deadline: null,
        group: this.group,
        category: this.category,
        status: TaskStatus.todo,
        activity_id: this.activityId,
        public: false,
        ordinal: this.ordinal,
    };

    async submit() {
        if (this.payload.title === "") {
            return;
        }

        this.loading = true;

        const task = await this.createTask(this.payload);

        if (!this.isDossier && this.defaultAssignee) {
            const accountTask = await this.createAccountTask({ account_id: Number(this.defaultAssignee.id), task_id: task.id });

            task.assigned_to = [
                {
                    id: Number(this.defaultAssignee.id),
                    relation_id: Number(accountTask.id),
                    first_name: this.defaultAssignee.first_name,
                    last_name: this.defaultAssignee.last_name,
                },
            ];

            this.mutateTask(task);
        }

        this.payload.title = "";

        this.payload.deadline = null;

        this.loading = false;
    }

    @Emit("assignee-changed")
    handleAssigneeChanged(assignees: { id: number; type: "user" | "seller" }[], task: Task) {
        return { assignees, id: task.id, seller_assigned_to: task.seller_assigned_to, assigned_to: task.assigned_to };
    }
}
